<template>
  <v-list class="transparent">
    <router-link
      v-for="(post, idx) in posts"
      :key="post.id"
      class="latest-post-link"
      :to="{
        name: routeName,
        params: { postName: post.slug }
      }"
    >
      <v-list-item class="pb-2 latest-post-item d-flex flex-column">
        <v-row class="d-flex py-2 justify-center align-center">
          <v-col cols="6"
            ><img :src="post.featured_image_url" class="rounded"
          /></v-col>
          <v-col cols="6"
            ><span class="lastest-post-title secondary--text font-weight-bold">
              {{ post.title.rendered }}
            </span></v-col
          >
        </v-row>
        <v-divider
          v-if="idx !== posts.length - 1"
          class="w-100 my-1"
        ></v-divider>
      </v-list-item>
    </router-link>
  </v-list>
</template>
<style lang="scss" scoped>
.latest-post-link {
  text-decoration: none;
}
.latest-post-item {
  cursor: pointer;
  min-height: inherit;
  .lastest-post-title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box !important;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}
</style>
<script>
import CMService from "~/service/cmService";
export default {
  name: "LatestsPosts",
  props: {
    type: {
      type: String,
      default: "post"
    }
  },
  data() {
    return {
      posts: null
    };
  },
  methods: {
    async fetchNews() {
      let params = [
        {
          filterName: "filter[post_type]",
          filterValue: this.type
        },
        {
          filterName: "post_type",
          filterValue: this.type
        },
        {
          filterName: "type",
          filterValue: this.type
        }
      ];

      params.push({
        filterName: "page",
        filterValue: this.page
      });

      if (this.cat != null && this.cat.length > 0) {
        params.push({
          filterName: "categories",
          filterValue: this.cat
        });
      }
      const res = await CMService.getCustomPostByFilters("posts", params, 8);

      if (res && res.data && res.data.length) {
        this.posts = res.data;
      } else {
        this.posts = null;
      }
    }
  },
  computed: {
    routeName() {
      switch (this.type) {
        case "post":
          return "NewsDetail";
        case "approfondimento":
          return "NewsDetail";
        case "video":
          return "VideoDetail";
        case "recipe":
          return "RecipeDetail";
        case "appuntino":
          return "RecipeDetail";
        case "mixology":
          return "RecipeDetail";
        case "faq":
          return "FaqiList";
        default:
          return "Page";
      }
    }
  },
  async mounted() {
    await this.fetchNews();
  }
};
</script>
